import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    type ElementRef,
    ViewEncapsulation,
    booleanAttribute,
    computed,
    inject,
    input,
    signal,
    viewChild,
} from '@angular/core';
import type { Icon } from '@hyundai/assets';
import {
    AnchorDirective,
    DealerFeature,
    FootnotesPipe,
    HyundaiPromiseFeature,
    HyundaiPromiseVMFeature,
    PLATFORM_TOKEN,
    Platform,
    RouterLinkDirective,
    TrackingDirective,
} from '@hyundai/ng-common-lib';
import { M000C010ImageComponent, M040C020TeaserAllrounderComponent } from '@hyundai/ui';
import { Store } from '@ngrx/store';

@Component({
    standalone: true,
    templateUrl: './promise-page.component.html',
    imports: [
        M040C020TeaserAllrounderComponent,
        RouterLinkDirective,
        M000C010ImageComponent,
        AnchorDirective,
        FootnotesPipe,
        TrackingDirective,
        NgClass,
    ],
    selector: 'lib-promise-page',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class PromisePageLibComponent {
    protected readonly PlatformType = Platform;
    protected readonly store = inject(Store);
    protected readonly platform = inject(PLATFORM_TOKEN);
    protected readonly videoElm = viewChild<ElementRef<HTMLVideoElement>>('videoElm');

    content = this.store.selectSignal(
        this.platform === this.PlatformType.HCM
            ? HyundaiPromiseVMFeature.state.selectLoadedContent
            : HyundaiPromiseFeature.state.selectLoadedContent,
    );
    dealer = this.store.selectSignal(DealerFeature.state.selectSubDealerOrDealer);
    locationHeadline = input<string>();
    dealerHcmUrl = input<string>();
    isOverlay = input(false, { transform: booleanAttribute });

    videoPlaying = signal(true);
    videoMuted = signal(true);

    constructor() {
        if (this.platform === this.PlatformType.HCM) {
            this.store.dispatch(HyundaiPromiseVMFeature.actions.loadHyundaiPromiseVM());
        } else {
            this.store.dispatch(HyundaiPromiseFeature.actions.loadHyundaiPromise());
        }
    }

    heroBullets = computed(() => {
        const content = this.content();

        if (!content) return;

        const heroBulletList: Array<{ icon: Icon; text: string }> = content.heroBulletListItems.map((b) => ({
            icon: 'icon-check',
            text: b.text,
        }));
        return heroBulletList;
    });

    toggleVideo() {
        const videoElm = this.videoElm()?.nativeElement;
        if (!videoElm) return;
        this.videoPlaying.set(!this.videoPlaying());
        this.videoPlaying() ? videoElm.play() : videoElm.pause();
    }
}
